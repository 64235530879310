import {
  Box,
  Flex,
  useColorModeValue,
  BoxProps,
  VStack,
  Divider,
  Text
} from '@chakra-ui/react'
import { dbSignOut } from 'controllers/auth'
import { useNavigate, useLocation } from 'react-router'
import _ from 'lodash'
import NavItem from 'components/NavItem'

interface LinkItemProps {
  name: string
  path?: string
}
const LinkItems: LinkItemProps[][] = [
  [
    { name: 'Flexible App', path: 'flex' },
    { name: 'Two Factor Demo', path: 'two_factor' },
    { name: 'Two Factor Localization', path: 'tf_localization' },
    { name: 'Interrupt', path: 'interrupt' },
    { name: 'AI Analysis', path: 'analysis' },
    { name: 'Translater', path: 'translater' },
    { name: 'Langs', path: 'langs' }
  ],
  [{ name: 'Clients', path: 'clients' }]
]

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ ...rest }: SidebarProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <VStack
      bg={'zinc.900'}
      borderRight='1px'
      borderRightColor={'zinc.700'}
      w={{ base: 'full', md: '44' }}
      pos='fixed'
      h='full'
      pt={4}
      align={'flex-start'}
      {...rest}
    >
      <VStack h='full' w='full' align={'flex-start'} divider={<Divider />}>
        {LinkItems.map((links, index) => (
          <VStack key={index} w='full' align={'start'}>
            {_.map(links, link => (
              <NavItem
                key={link.name}
                path={link.path}
                isSelected={location.pathname.includes(link.path || '')}
              >
                <Text fontSize={'sm'} textAlign={'start'}>
                  {link.name}
                </Text>
              </NavItem>
            ))}
          </VStack>
        ))}
      </VStack>

      <Box w='full' pb={2}>
        <NavItem onClick={() => dbSignOut(navigate)}>Sign Out</NavItem>
      </Box>
    </VStack>
  )
}

export default SidebarContent
