import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure
} from '@chakra-ui/react'
import SidebarContent from 'components/SideBarContent'

export default function SimpleSidebar () {
  const { isOpen, onClose } = useDisclosure()
  return (
    <Box minH='100vh' bg={useColorModeValue('zinc.100', 'zinc.900')}>
      <SidebarContent onClose={() => onClose} />
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box w={44}>{/* Content */}</Box>
    </Box>
  )
}
